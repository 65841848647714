// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// const { $ } = require("@rails/ujs")

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on("turbolinks:load", function() {
  $(document).on("click", ".inventory-add-link", function(e) {
    e.preventDefault()
  
    inventory_id = $(this).data("inventory-id")
    console.log(inventory_id)
    console.log($("#quantity-"+inventory_id).val())
  
    $("#line_item_inventory_id").val(inventory_id)
    $("#line_item_quantity").val($("#quantity-"+inventory_id).val())
  
    // $("form#new_line_item").submit()
  })

  $quote_user_search = $('#quote-user-search');

  if ($quote_user_search != undefined) {
    var options = {
      url: function(q) {
        return "/users.json?q[first_name_or_last_name_or_full_name_or_zip_or_company_or_phone_or_email_i_cont]=" + q;
      },
      getValue: "search_string",
      template: {
        type: "custom",
        method: function(value, item) {
          return "<a href='" + window.location.href + "&customer_id=" + item.id + "' />" + value + "</a>";
        }
      }
    };

    $quote_user_search.easyAutocomplete(options);
  }

  $admin_user_search = $('#admin-user-search');

  if ($admin_user_search != undefined) {
    var options = {
      url: function(q) {
        return "/users.json?q[first_name_or_last_name_or_full_name_or_zip_or_company_or_phone_or_email_i_cont]=" + q;
      },
      getValue: "search_string",
      template: {
        type: "custom",
        method: function(value, item) {
          return "<a href='/orders?customer_id=" + item.id + "' />" + value + "</a>";
        }
      }
    };

    $admin_user_search.easyAutocomplete(options);
  }

  $admin_inventory_search = $('#admin-inventory-search');

  if ($admin_inventory_search != undefined) {
    var options = {
      url: function(q) {
        return "/inventories.json?q=" + q;
      },
      getValue: "search_string",
      template: {
        type: "custom",
        method: function(value, item) {
          return "<a href='/parts/" + item.id + "' />" + value + "</a>";
        }
      }
    };

    $admin_inventory_search.easyAutocomplete(options);
  }

  $admin_order_search = $('#admin-order-search');

  if ($admin_order_search != undefined) {
    var options = {
      url: function(q) {
        return "/orders.json?q=" + q;
      },
      getValue: "search_string",
      template: {
        type: "custom",
        method: function(value, item) {
          return "<a href='/orders/" + item.id + "' />" + value + "</a>";
        }
      }
    };

    $admin_order_search.easyAutocomplete(options);
  }

  $inventory_user_search = $('#inventory-user-search');

  if ($inventory_user_search != undefined) {
    var options = {
      url: function(q) {
        return "/users.json?q[first_name_or_last_name_or_full_name_or_zip_or_company_i_cont]=" + q;
      },
      getValue: "full_name",
      list: {
        onClickEvent: function() {
          $("#inventory_user_id").val($inventory_user_search.getSelectedItemData().id);
        }
      }
    };

    $inventory_user_search.easyAutocomplete(options);
  }

  $vehicle_user_search = $('#vehicle-user-search');

  if ($vehicle_user_search != undefined) {
    var options = {
      url: function(q) {
        return "/users.json?q[first_name_or_last_name_or_full_name_or_zip_or_company_i_cont]=" + q;
      },
      getValue: "full_name",
      list: {
        onClickEvent: function() {
          $("#vehicle_user_id").val($vehicle_user_search.getSelectedItemData().id);
        }
      }
    };

    $vehicle_user_search.easyAutocomplete(options);
  }

  $assembly_search = $('#assembly-search');

  if ($assembly_search != undefined) {
    var options = {
      url: function(q) {
        return "/parts.json?assembly=true&q=" + q;
      },
      getValue: "number",
      list: {
        onClickEvent: function() {
          debugger;
          $("#illustration_part_part_id").val($assembly_search.getSelectedItemData().id);
        }
      }
    };

    $assembly_search.easyAutocomplete(options);
  }

  $part_search = $('#part-search');

  if ($part_search != undefined) {
    var options = {
      url: function(q) {
        return "/parts.json?assembly=true&q=" + q;
      },
      getValue: "number",
      list: {
        onClickEvent: function() {
          debugger;
          $("#inventory_part_id").val($part_search.getSelectedItemData().id);
        }
      }
    };

    $part_search.easyAutocomplete(options);
  }


  $source_search = $('#source-search');

  if ($source_search != undefined) {
    var options = {
      url: function(q) {
        return "/vehicles.json?q=" + q;
      },
      getValue: "id",
      list: {
        onClickEvent: function() {
          console.log($source_search.getSelectedItemData().make)
          $("#inventory_year").val($source_search.getSelectedItemData().year);
          $("#inventory_make_name").val($source_search.getSelectedItemData().make);
          $("#inventory_modelname").val($source_search.getSelectedItemData().model);
          $("#inventory_mileage").val($source_search.getSelectedItemData().mileage);
          $("#inventory_body_type").val($source_search.getSelectedItemData().body);
          $("#inventory_drive_type").val($source_search.getSelectedItemData().drive);
          $("#inventory_trim_level").val($source_search.getSelectedItemData().trim);
          $("#inventory_vin").val($source_search.getSelectedItemData().vin);
          $("#inventory_vehicle_id").val($source_search.getSelectedItemData().id);
        }
      }
    };

    $source_search.easyAutocomplete(options);
  }

  $(document).on("dblclick", ".dblclick-edit", function() {
    $(this).find("span").hide();
    $(this).find("form").show();
  })
  
  $(document).on("change", "#part_bulk", function() {
    $("#bulk-prices-row").toggle();
  })
  
  $(document).on("click", "#refund-btn", function() {
    $(this).hide();
    $("#refund-form").show();
    $(".line-item-refund-inputs").show();
  })

  $(document).on("click", "#process-refund-btn", function(e) {
    e.preventDefault();
  
    line_item_refunds=[];
    $(".line_item-refund_price").each(function() {
      if ($(this).val() != "") {
        line_item_refunds.push($(this).data("id") + ":" + Math.round(parseFloat($(this).val().replace(/,/g, ''))*100));
      }
    })
    $("#order_line_item_refunds").val(line_item_refunds.join(","))
  
    $(this).parent().trigger("submit")
  })

  $(document).on("click", "#restock-btn", function() {
    $(this).hide();
    $("#restock-form").show();
    $(".line-item-restock-inputs").show();
  })

  $(document).on("click", "#process-restock-btn", function(e) {
    e.preventDefault();
  
    line_item_restocks=[];
    $(".line_item-restock_quantity").each(function() {
      if ($(this).val() != "") {
        line_item_restocks.push($(this).data("id") + ":" + $(this).val());
      }
    })
    $("#order_line_item_restocks").val(line_item_restocks.join(","));
  
    $(this).parent().trigger("submit");
  })

  $(document).on("click", "#stripe-payment-btn", function(e) {  
    $("#payment-div").hide();
    $("#payment-refresh-btn").show();
  })

  $(document).on("click", ".payment-type-btn", function(e) {
    $("#mark-as-paid-btn").removeAttr('disabled');
  })


  $(document).on("submit", ".edit_user", function(e) {
    e.preventDefault();
    $("input:disabled").removeAttr("disabled");
    e.currentTarget.submit();
  })

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  // $(window).on('load', function () {
  //   $("#sign_in_btn").removeAttr("disabled").prop("value", "Sign In")
  //   $("#sign_up_btn").removeAttr("disabled").prop("value", "Sign Up")
  // })
});

